<template>
  <c-card :loading="cardLoading" :title="item.name" subTitle="Format Penomoran">
    <template slot="action">
      <c-button v-if="cP(15)" @click="add"><feather-icon icon="PlusIcon"></feather-icon> Tambah</c-button>
    </template>
    <h5 class="mb-2">Contoh Hasil : <b>{{code}}</b></h5>
    <b-table :fields="fields" :items="items" responsive>
      <template #cell(id)="{item}">
        <b-button-group size="sm">
          <b-button v-if="cP(16)" @click="edit(item)" variant="primary"><feather-icon icon="EditIcon"></feather-icon></b-button>
          <b-button v-if="cP(17)" @click="deletes(item.id)" variant="danger"><feather-icon icon="TrashIcon"></feather-icon></b-button>
        </b-button-group>
      </template>
    </b-table>
    <b-modal ref="modalNumbering" no-close-on-backdrop hide-footer>
      <template #modal-header="{close}">
        <h5>Format Penomoran</h5>
        <div>
          <c-button size="sm" @click="close()" variant="outline-primary">Batal</c-button>
          <c-button size="sm" @click="$refs.formNumbering.onSubmit()">Simpan</c-button>
        </div>
      </template>
      <c-form ref="formNumbering" @submit="submitForm">
        <form-input v-model="formData.sort_no" label="No Urut" :rules="{required: true, numeric: true}"></form-input>
        <form-input v-model="formData.prefix" label="Prefix"></form-input>
        <form-input v-model="formData.suffix" label="Suffix"></form-input>
        <form-select-static @option:selected="formData.format=null" :rules="{required: true}" :options="typeOptions" v-model="formData.type" label="Tipe"></form-select-static>
        <form-input :hidden="typeNumbering!=`Counter`" :rules="{numeric: true, required: typeNumbering==`Counter`}" v-model="formData.digit" label="Digit"></form-input>
        <form-input :hidden="typeNumbering!=`Counter`" :rules="{numeric: true, required: typeNumbering==`Counter`}" v-model="formData.last_value" label="Nilai Terakhir"></form-input>
        <form-select-static :hidden="typeNumbering==`Counter`" :rules="{required: typeNumbering.includes('Date','Roman')}" :options="formatOptions" v-model="formData.format" label="Format"></form-select-static>
        <button hidden type="submit"></button>
      </c-form>
    </b-modal>
  </c-card>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import {BTable,BButtonGroup,BButton,BModal} from 'bootstrap-vue'
import CButton from '@/utils/components/CButton.vue'
import CForm from '@/utils/components/CForm.vue'
import FormInput from '@/utils/components/FormInput.vue'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'

export default {
  components: { CCard, BTable, BButtonGroup, BButton, CButton, BModal, CForm, FormInput, FormSelectStatic},
  data(){
    const fields = [
      {key:'sort_no',label:'No.'},
      {key:'prefix',label:'Prefix'},
      {key:'suffix',label:'Suffix'},
      {key:'type',label:'Tipe'},
      {key:'name_format',label:'Format'},
      {key:'last_value',label:'Nilai Terakhir'},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    const formData = {
      sort_no:'',
      prefix:'',
      suffix:'',
      type:null,
      format:null,
      name_format:'',
      last_value:1,
      id_type_transaction: this.$route.params.id,
      digit:1
    }
    const typeOptions = [
      {"label": "Romawi", "value": "Roman"},
      {"label": "Date", "value": "Date"},
      {"label": "Counter", "value": "Counter"}
    ]
    const format1 =[
      { "label": "Tahun 4-Digit (yyyy)", "value": "R-Y"},
      { "label": "Tahun 2-Digit (yy)", "value": "R-y"},
      { "label": "Bulan (01-12)", "value": "R-m"},
      { "label": "Day (01-31)", "value": "R-d"},
    ]
    const format2 = [
      { "label": "Tahun 4-Digit (yyyy)", "value": "D-Y"},
      { "label": "Tahun 2-Digit (yy)", "value": "D-y"},
      { "label": "Bulan (01-12)", "value": "D-m"},
      { "label": "Bulan Text (January - Desember)", "value": "D-M"},
      { "label": "Day (01-31)", "value": "D-d"},
    ]
    return {
      item:{},
      cardLoading: true,
      items:[],
      code:'',
      fields,
      formData,
      typeOptions,
      format1,
      format2
    }
  },
  computed:{
    typeNumbering(){
      let type = ''
      if(this.formData.type) type = this.formData.type.value
      return type
    },
    formatOptions(){
      if(this.typeNumbering == "Roman") return this.format1
      else return this.format2
    }
  },
  methods:{
    async getMain(){
      const id = this.$route.params.id
      const {data} = await this.$http.get(`v1/format_transactions/show`,{params:{id}})
      this.item = data
    },
    async getList(){
      const id = this.$route.params.id
      const {data:{data,code}} = await this.$http.get(`v1/format_transactions/list`,{params:{id}})
      this.code = code
      this.items = data
    },
    add(){
      this.formData = {
        sort_no:'',
        prefix:'',
        suffix:'',
        type:null,
        format:null,
        name_format:'',
        last_value:1,
        id_type_transaction: this.$route.params.id,
        digit:1
      }
      this.$refs.modalNumbering.show()
    },
    edit(item){
      this.formData = {
        sort_no: item.sort_no,
        prefix: item.prefix,
        suffix: item.suffix,
        type: {value:item.type,label:item.type},
        format: {value:item.format,label:item.name_format},
        name_format: item.name_format,
        last_value: item.last_value,
        id_type_transaction: this.$route.params.id,
        digit: item.digit,
        id: item.id
      }
      this.$refs.modalNumbering.show()
    },
    deletes(id){
      this.confirmation(`confirmDelete`,{parameter:id})
    },
    async confirmDelete(id){
      const vm = this
      await this.$http.delete(`v1/format_transactions/delete`,{params:{id,name:vm.item.name}})
      vm.notify(`Penomoran Berhasil Dihapus!`)
      vm.getList()
    },
    submitForm(){
      this.confirmation('confirmSubmitForm')
    },
    async confirmSubmitForm(){
      const vm = this
      let fd = JSON.parse(JSON.stringify(vm.formData))
      fd.type = fd.type.value
      if(vm.typeNumbering==`Counter`) {
        fd.format = '0'.repeat(fd.digit)
        fd.name_format = `Counter "${'0'.repeat(fd.digit)}"`
      } else {
        fd.format = fd.format.value
        fd.name_format = vm.formData.format.label
      }

      if(!fd.id) await vm.$http.post(`v1/format_transactions/create`,fd)
      else await vm.$http.put(`v1/format_transactions/update`,fd,{params:{id:fd.id,name:vm.item.name}})
      vm.$refs.modalNumbering.hide()
      vm.getList()
      vm.notify(`Data Berhasil Disimpan`)
    }
  },
  mounted(){
    const vm = this
    vm.cardLoading = true
    Promise.all([
      vm.getMain(),
      vm.getList()
    ]).then(()=>{
      vm.cardLoading = false
    })
  }
}
</script>

<style>

</style>